
import { defineComponent, ref, onMounted } from "vue";
import Gender from "@/components/reusable/Gender.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "create-policy-migration-profile-component",
  components: {
    ErrorMessage,
    Field,
    Form,
    Gender,
  },
  props: {
    profileCreation: String,
    redirect: String,
  },
  data() {
    return {
      cooperative: "",
      emailAddress: "",
      firstName: "",
      gender: "",
      lastName: "",
      maritalStatus: "",
      phoneNumber: "",
    };
  },
  setup(props) {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const cooperatives = ref([
      {
        id: "",
        name: "",
        publicId: "",
      },
    ]);

    const newMemberDetailsValidator = Yup.object().shape({
      firstName: Yup.string()
        .required()
        .label("First name"),
      lastName: Yup.string()
        .required()
        .label("Last name"),
      emailAddress: Yup.string()
        .required()
        .label("Email address"),
      phoneNumber: Yup.number()
        .required()
        .label("Phone number"),
      gender: Yup.mixed()
        .required()
        .label("Gender"),
      // maritalStatus: Yup.mixed()
      //   .required()
      //   .label("Martial status"),
      cooperative: Yup.mixed()
        .notRequired()
        .label("Cooperative"),
    });

    // Function to get underwriters
    const getCooperatives = async () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.SUPERADMIN_COOPERATIVE_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            cooperatives.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const createMember = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(variables.LEGACY_CLIENT, values)
              .then((response) => {
                console.log(response);
                variables.toastAlert(
                  "Account was created successfully.",
                  "success"
                );
                resetForm();
                router.push({
                  name: props.redirect,
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    // Call the getCooperatives function when the component is mounted
    onMounted(() => {
      getCooperatives();
    });

    return {
      submitButton1,
      createMember,
      newMemberDetailsValidator,
      cooperatives,
    };
  },
  methods: {
    acceptNumberOnly(event) {
      variables.acceptNumberOnly(event);
    },
  },
});
