
import { defineComponent } from "vue";
import { Field } from "vee-validate";

export default defineComponent({
  name: "create-policy-migration-component",
  components: {
    Field,
  },
  methods: {
    motorInsuranceOption() {
      return this.$router.push({
        name: "admin-policy-migration-motor-creation",
      });
    },
    houseInsurance() {
      return this.$router.push({
        name: "admin-policy-migration-house-creation",
      });
    },
  },
});
