
import { defineComponent } from "vue";
import CreateProfile from "@/components/migration/CreateProfile.vue";
import CreatePolicy from "@/components/migration/CreatePolicy.vue";
import { Field } from "vee-validate";

export default defineComponent({
  name: "migration-documentation-process",
  components: {
    Field,
    CreateProfile,
    CreatePolicy,
  },
  props: {
    profileCreation: String,
    redirect: String,
  },
  data() {
    return {
      displayProfile: false,
      displayPolicies: false,
      clientsRoute: "",
      policyRoute: "",
      policyDetailsRoute: "",
      clientPoliciesRoute: "",
      role: "",
    };
  },
  methods: {
    createProfile() {
      this.displayPolicies = false;
      this.displayProfile = true;
    },
    createPolicy() {
      this.displayProfile = false;
      this.displayPolicies = true;
    },
  },
});
